@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    font-family: "Roboto", system-ui !important;
    font-style: normal !important;
    font-size: 1rem;
}

.card-magasin {
    position: relative;
}

.card-magasin .btn-note {
    position: absolute;
    top: 10px;
    right: 2px;
}

.card-magasin:hover {
    -webkit-box-shadow: 10px 15px 83px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 15px 83px -12px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 15px 60px 0px rgba(0, 0, 0, 0.75);
}

.scalble {
    transition: all .2s ease-in;
    transform: scale(1.1);

}

.pointer {
    cursor: pointer;
}

.scrollable {
    overflow-y: scroll !important;
    scrollbar-width: thin;
}

.dropdown-list {
    display: none !important;
    transition: all .7s ease-in
}

.show {
    display: block !important;
}

a,
.a {
    cursor: pointer !important;
}

.hide-arrow::-webkit-outer-spin-button,
.hide-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.hide-arrow {
    -moz-appearance: textfield !important;
}

td input[type="text"],
td input[type="number"] {
    min-width: 170px !important;
}

.btn-card {
    position: fixed;
    top: 50%;
    right: 5px;
    z-index: 100;
    height: 70px !important;
    width: 70px !important;
    border-radius: 50% !important;
    text-align: center !important;
}

.btn-card span {
    position: absolute;
    top: 5px;
    right: 8px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;

}

.position-relative {
    position: relative;
}

.position-relative .position-absolute {
    position: absolute;
    top: 50%;
    right: 50%;
}


.photo {
    height: 200px;
    width: 200px;
    border-radius: 50%;
}

.camera {
    position: absolute;
    top: 75%;
    right: 20px;
    height: 50px;
    width: 50px;
    border-radius: 50% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.list-clients {
    list-style: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
}

.list-clients .client-item {
    user-select: none;
    cursor: pointer;
    transition: ease-in .5s;
    display: none;
}

.list-clients .client-item:hover {
    background-color: rgba(145, 146, 147, 0.586);
}

.fact {
    height: 800px !important;
    width: 900px !important;
}

#facture {
    user-select: none;
}

.btn-print {
    position: fixed;
    right: 10px;
    top: 50%;
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
    width: 100px;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-color: #ccc #0000;
    animation: l16 1s infinite linear;
    position: fixed;
    right: 40%;
    top: 50%;
    z-index: 1000;
}

.loader::before,
.loader::after {
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
}

.loader::before {
    border-color: #f03355 #0000;
    animation: inherit;
    animation-duration: .5s;
    animation-direction: reverse;
}

.loader::after {
    margin: 8px;
}

@keyframes l16 {
    100% {
        transform: rotate(1turn)
    }
}

.link {
    cursor: pointer;
    list-style: none;
    transition: ease-in .5;
    user-select: none;
}

.link:hover,
.link.active {
    background-color: #ffffff;
    color: blue;
}

img#logo {
    width: 150px;
    aspect-ratio: 1;
    position: absolute;
    left: 5px;
    transform: translateY(-10%);
}

small {
    font-size: 12px !important;
    padding-left: 5px !important;
}

.logo-icon {
    max-width: 150px !important;
    width: 70% !important;
    min-width: 50px !important;
    border-radius: 50%;
    /* object-fit: contain; */

}

.dropdown-menu {
    z-index: 100 !important;
}

.fill-content {
    min-height: 500px !important;
}

.lef-liste {
    height: 600px !important;
    overflow-y: scroll !important;
}

fieldset,
legend {
    all: revert !important;
}

.btn.position-fixed {
    z-index: 100 !important;
    position: fixed !important;
    bottom: 10% !important;
    right: 45px !important;
    /* left: 0!important; */
}

.photo-travailleur {
    height: 100px !important;
    width: 120px !important;
    object-fit: fill;

    border-radius: 50%;
}

.small {
    font-size: small !important;
}

.sidebar-wrapper .metismenu {
    margin: 5px !important;

}

span.toggle-icon {
    position: absolute;
    top: 2px;
    right: 10px;
}

.logo-text {
    font-size: 100% !important;
}

.fact-clien-input {
    min-width: 80% !important;
}